import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29')
];

export const server_loads = [0,2,4];

export const dictionary = {
		"/(app)": [5,[2]],
		"/auth/login": [~29,[4]],
		"/(app)/campaign": [6,[2]],
		"/(app)/campaign/booking": [10,[2]],
		"/(app)/campaign/booking/[id=uuid]": [11,[2]],
		"/(app)/campaign/category": [12,[2]],
		"/(app)/campaign/category/create": [~14,[2]],
		"/(app)/campaign/category/[id]": [~13,[2]],
		"/(app)/campaign/create": [~15,[2]],
		"/(app)/campaign/[campaignId=integer]": [7,[2,3]],
		"/(app)/campaign/[campaignId=integer]/edit": [~8,[2,3]],
		"/(app)/campaign/[campaignId=integer]/edit/page/[pageId=integer]": [~9,[2,3]],
		"/(app)/facility": [16,[2]],
		"/(app)/facility/[id]": [17,[2]],
		"/(app)/facility/[id]/campaign": [18,[2]],
		"/(app)/facility/[id]/customer": [19,[2]],
		"/(app)/facility/[id]/lead-settings": [20,[2]],
		"/(app)/facility/[id]/tariffs": [21,[2]],
		"/(app)/features": [22,[2]],
		"/(app)/lead": [23,[2]],
		"/(app)/lead/[id]": [24,[2]],
		"/(app)/member": [25,[2]],
		"/(app)/member/signup": [26,[2]],
		"/(app)/member/signup/[id=integer]": [27,[2]],
		"/(app)/reporting": [28,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const hash = false;

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';